// import axios from 'axios';
// import {env as environment} from '../environments'


// export const sendSentryLogs = async (sessionObjData) => {

//     const headers ={
//         "Content-Type": "application/json"
//     }

//     const response = await axios.post(environment.sentryLogs,sessionObjData,headers)
//     if(response.status === 200){
        
//         return;
//     }
//     else{
        
//     }
// }

import * as Sentry from '@sentry/react';
import {env as environment} from '../environments'

        Sentry.init({
            dsn: environment.sentryDNS,
          
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
        });
         // const transaction = Sentry.startTransaction({
    //     op: "extract-answer module",
    //     name: "extract-answer Transaction",
    // });
    
   export  const sentryAlert = (error, sessionId, facility,errorCode) => {
    Sentry.setTags({
        env:environment,
        sessionId: sessionId,
        error: errorCode,
        facility,
      });
      Sentry.captureException(error);
    }


