import axios from "axios";
import { env as environment } from "../environments";
import {
  CORRUPT_FILE,
  LIVEFEEDBACK_INVALID_DOC,
  LIVEFEEDBACK_START,
  LIVEFEEDBACK_SUCCESS,
  PASSWORD_PROTECTED,
  SOMETHING_WENT_WRONG,
} from "./actionTypes";
import { updateSession } from "../helpers/updateSessionHandler";
import { sentryAlert } from "../helpers/sentry_logs";
export const liveFeedback = (file, category, sessionId) => {
  return async (dispatch) => {
    try {
      // if category is either bank or address then only livefeedback has to work
      if (category === "bank" || category === "address") {
        dispatch({ type: LIVEFEEDBACK_START });

        const Header = {
          "Content-Type": "application/json",
        };

        const liveFeedbackForm = new FormData();
        let datetime = new Date().getTime().toString();

        const key = sessionId + "-" + datetime + ".pdf";
        liveFeedbackForm.append("file", file, key);
        liveFeedbackForm.append("category", category);
        liveFeedbackForm.append("sessionid", sessionId);

        const response = await axios.post(
          environment.getProcessedDataFromChatGpt,
          liveFeedbackForm,
          Header
        );
        var send_livefeedback = {
          sessionid: sessionId,
          data: { livefeedback: response.data },
        };
        dispatch(updateSession(send_livefeedback, sessionId));

        var uploadSessionStart = {
          sessionid: sessionId,
          data: {
            indicator: {
              progress_no: "4",
              progress_name: "Live Feedback",
              Detection: "Upload",
            },
          },
        };
        dispatch(updateSession(uploadSessionStart, sessionId));

        if (
          response.status === 200 &&
          response?.data?.response?.document_type === "Invalid"
        ) {
          // To call the wrong document popup, call the below action with the given payload

          dispatch({ type: LIVEFEEDBACK_INVALID_DOC, payload: true });
        } else if (
          response?.data?.error == true &&
          response?.data?.message?.toLowerCase().includes("password")
        ) {
          dispatch({ type: PASSWORD_PROTECTED, payload: true });
        } else if (
          (response?.data?.error == true &&
            response?.data?.message
              ?.toLowerCase()
              .includes("Failed to parse PDF document".toLowerCase())) ||
          response?.data?.message
            ?.toLowerCase()
            .includes("Data is not extracted from pdf".toLowerCase())
        ) {
          dispatch({ type: CORRUPT_FILE, payload: true });
        } else if (response.status !== 200 || response?.data?.error) {
          dispatch({ type: SOMETHING_WENT_WRONG, payload: true });
        }

        const chatGptResponse = response?.data;
        dispatch({ type: LIVEFEEDBACK_SUCCESS, payload: chatGptResponse });
      }
    } catch (error) {
      // if(response.status === 413){
      dispatch({ type: SOMETHING_WENT_WRONG, payload: true });
      // }

      sentryAlert(error, sessionId, "liveFeedback", "E28");
    }
  };
};
